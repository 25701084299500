<template>
	<div>
		<nav class="navbar navbar-dark navbar-expand-xl">
			<div class="container">
				<router-link to="/" class="navbar-brand">
					<img
						src="@/assets/images/logo/KCIC_Abbreviated_Logo.png"
						height="50"
						alt="KCIC-Logo"
						title="Kansas City Islamic Center"
					/>
				</router-link>

				<button
					class="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarResponsive"
					@click="toggleNav"
				>
					<span v-if="!isOpen" class="navbar-toggler-icon"></span>
					<i
						style="
							font-size: 1.5em !important;
							width: 30px !important;
							margin: auto;
						"
						v-if="isOpen"
						class="fa-solid fa-xmark"
					></i>
				</button>

				<div
					class="nav-items"
					id="navbarResponsive"
					:style="
						useHamburger
							? (isOpen
									? 'transform: translateY(0); height: calc(100vh - 60px)!important;'
									: 'transform: translateY(-1000px); height: calc(100vh - 60px)!important;') +
							  ' overflow-y: scroll!important'
							: 'transform: translateY(0)'
					"
				>
					<div class="navbar-nav">
						<div class="nav-item">
							<router-link to="/" class="nav-link"
								>Home</router-link
							>
						</div>
						<div
							class="nav-item"
							style="
								display: flex;
								align-items: center;
								transform: translateY(-5px);
							"
						>
							<a
								href="https://payments.madinaapps.com/kcic"
								target="_blank"
								class="btn btn-primary btn-lg"
                                style="border-radius: 15px;"
							>
								<span class="px-3">Donate</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>

	<div class="page-cover" v-if="isOpen && useHamburger"></div>
</template>

<script>
import Dropdown from "./Dropdown.vue";

export default {
	components: {
		Dropdown,
	},
	watch: {
		width: function () {
			if (this.width < 1200) {
				this.useHamburger = true;
			} else {
				this.useHamburger = false;
				this.$emit("close");
			}
		},
		useHamburger: function () {},
	},
	data() {
		return {
			isOpen: false,
			useHamburger: false,
			width: 0,
		};
	},
	methods: {
		toggleNav() {
			this.isOpen = !this.isOpen;

			if (this.isOpen && this.useHamburger) {
				this.$emit("open");
			} else {
				this.$emit("close");
			}
			console.log("toggleNav");
			console.log(this.isOpen);
		},
	},
	mounted() {
		window.addEventListener("resize", () => {
			this.width = window.innerWidth;
		});

		this.width = window.innerWidth;

		console.log("useHamburger: " + this.useHamburger);
		console.log("isOpen: " + this.isOpen);
	},
};
</script>

<style>
nav {
	width: 100%;
	z-index: 50;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}

nav,
.navbar,
.navbar-dark,
.navbar-expand-xl {
	width: 100%;
	padding: 0 20px 0 20px !important;
	background-color: rgba(0, 0, 0, 0.65);
	height: var(--navbar-height) !important;
	position: fixed;
	top: 0;
}

.nav-items {
	float: right;
	height: 60px !important;
}

.nav-items.mobile {
	display: none;
}

.navbar-brand img {
	/* filter: invert(72%) sepia(54%) saturate(296%) hue-rotate(1deg) brightness(96%)
			contrast(86%); */
}

.nav-link {
	width: fit-content !important;
	height: var(--navbar-height);
	text-align: center;
	font-size: 14pt;
	transition: box-shadow 0.5s;
	display: inline-block;
}

.nav-link:hover {
	box-shadow: inset 0px -25px 15px -15px var(--red);
}

.row {
	width: 100% !important;
}

.nav-item {
	padding: 0 !important;
	padding-top: 5px !important;
	align-content: center;
	height: 60px !important;
	margin-left: 40px;
}

.router-link-active {
	color: white !important;
}

@media (max-width: 1199.98px) {
	.navbar-toggler {
		float: right;
		color: var(--primary) !important;
	}

	.navbar-toggler:focus {
		box-shadow: none;
	}

	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 122, 54, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
	}

	.nav-items {
		position: absolute;
		top: var(--navbar-height);
		left: 0;
		width: 100vw;
		height: unset !important;
		transition: all 0.5s ease-in-out;
		transform-origin: left top;
	}

	.nav-item {
		background-color: transparent !important;
		margin: 0;
		height: unset !important;
	}

	.page-cover {
		width: 1000vw !important;
		height: 1000vh !important;
		overflow: hidden;
		z-index: 49;
		background-color: transparent;
		position: fixed;
		background-color: rgba(0, 0, 0, 0.85);
	}

	.nav-link {
		text-align: unset !important;
		padding-left: 2rem !important;
	}
}
</style>
