<template>   
	<!-- Hero -->
	<Parallax
		:image="{
			backgroundImage: `url(${require('@/assets/images/misc/KansasCity.jpg')})`,
			'background-position': 'top',
		}"
		:height="'70vh'"
		:opacity="0.3"
		:overlayColor="'0, 0, 0'"
		class="section"
	>
		<div class="container nav-pad" style="height: 70vh">
			<div class="vertical-center">
				<img
					src="@/assets/images/logo/KCIC_Decorative_Logo.png"
					style="max-height: 55vh; max-width: 100%"
				/>
			</div>
		</div>
	</Parallax>


	<!-- PRAYER TIMES -->
	<div class="section" style="background-color: var(--primary)">
		<PrayerTimes />
	</div>

	<!-- ANNOUNCEMENTS -->
	<Parallax
		:image="{
			backgroundImage: `url(${require('@/assets/images/Background.svg')})`,
			'background-color': '#fff',
		}"
		:height="'10vh'"
		:opacity="0.9"
		:overlayColor="'240, 240, 240'"
		class="section py-5"
	>
		<div
			class="w-100"
			style="
				display: flex;
				justify-content: center;
				flex-direction: column;
			"
		>
			<h1 class="center primary mt-5 mb-4 white-shadow">Announcements</h1>
			
			<!-- Quran Class Announcement 
				<div class="vertical-center">
					<img
						src="@/assets/images/misc/KCIC_Kids_Summer_Quran_Class_English_Flyer.jpg"
						style="
							max-height: 55vh;
							max-width: 100%;
						"
					/>
				</div>
			-->
					
				
				
			<!-- testing facebook embed -->
			<iframe
				:src="`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100093084463868%26mibextid%3DZbWKwL&tabs=timeline&width=${
					windowWidth > 500 ? 500 : windowWidth
				}&height=${windowHeight}&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`"
				style="
					border: none !important;
					overflow: hidden !important;
					height: 70vh !important;
					max-width: 500px !important;
					width: 100% !important;
					margin: auto !important;
				"
				scrolling="no"
				frameborder="0"
				allowfullscreen="true"
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
			></iframe>
			
			<!-- <iframe
				:src="`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100091673155165&tabs=timeline&width=${
					windowWidth > 500 ? 500 : windowWidth
				}&height=${windowHeight}&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`"
				style="
					border: none !important;
					overflow: hidden !important;
					height: 70vh !important;
					max-width: 500px !important;
					width: 100% !important;
					margin: auto !important;
				"
				scrolling="no"
				frameborder="0"
				allowfullscreen="true"
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
			></iframe> -->
			<!-- <iframe
				:src="`https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fisglfl%2Fposts%2Fpfbid023WNnsvPbhd9KM22otSUTJ8uHTFyvaAhejuTsXVXxtZHL47Suw2pMbuFSU7TvRE7Nl&show_text=true&width=${windowWidth}`"
				style="
					border: none;
					overflow: hidden;
					width: 90vw;
					height: 70vh;
                    background-color: white;
				"
				scrolling="no"
				frameborder="0"
				allowfullscreen="true"
				allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
			></iframe> -->
		</div>
	</Parallax>

	<!-- DONATE -->
	<Parallax
		:image="{
			backgroundImage: `url(${require('@/assets/images/masjid/inside/Inside6.jpg')})`,
		}"
		:overlayColor="'0, 0, 0'"
		:opacity="0.6"
		:height="'10vh'"
		class="section"
	>
		<!-- Three cards -->
		<div class="container py-5">
			<h4 class="center mt-5 white mb-0">Support Us</h4>
			<h1 class="center primary mb-4 white">We Need Your Help</h1>
			<!-- Paragraph talking about importance of donations -->
			<p class="center white mb-5">
				Your donations help us to continue our work in the community,
				and to provide the best possible services to our members.
			</p>
			<h4 class="normal center mb-4"></h4>

			<div class="center">
				<a
					href="https://payments.madinaapps.com/kcic"
					target="_blank"
					class="btn btn-primary donate-btn mb-5 py-3"
				>
					<h4
						style="display: inline; vertical-align: middle"
						class="py-3 px-md-5 mx-md-4"
					>
						Donate
					</h4>
				</a>
			</div>
		</div>
	</Parallax>

	<!-- LOCATION -->
	<div class="section py-5" style="background-color: var(--primary)">
		<h1 class="center white mb-3">Location</h1>
		<iframe
			src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d231.37514282372425!2d-94.638389!3d39.081421!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c0f1dff637befd%3A0x18cdd4c81e7f4274!2sKansas%20City%20Islamic%20Center%20(KCIC)!5e1!3m2!1sen!2sus!4v1694791670677!5m2!1sen!2sus"
			style="height: 50vh; width: 90%; border: 0; margin-left: 5%"
			allowfullscreen=""
			loading="lazy"
			referrerpolicy="no-referrer-when-downgrade"
		></iframe>
	</div>

	<!-- SERVICES -->
	<Parallax
		:image="{
			backgroundImage: `url(${require('@/assets/images/Background.svg')})`,
			'background-color': '#fff',
		}"
		:height="'10vh'"
		:opacity="0.9"
		:overlayColor="'240, 240, 240'"
		class="section"
		id="services"
	>
		<!-- Three cards -->
		<div class="container py-5">
			<h1 class="center primary mt-5 mb-4 white-shadow">Our Services</h1>
			<div class="row mb-5 block-center">
				<div class="col-md-4">
					<div class="card">
						<!-- Card Icon -->
						<div class="card-icon">
							<i class="fa-solid fa-mosque"></i>
						</div>
						<div class="card-body">
							<h5 class="card-title primary">Jumu'ah</h5>
							<p class="card-text">
								Weekly khutbah and Jumu'ah prayer at 1:00 PM on
								Fridays
							</p>
							<button class="btn btn-primary">
								Read More
								<i class="fa-solid fa-arrow-right"></i>
							</button>
						</div>
					</div>
				</div>
				<br />
				<div class="col-md-4">
					<div class="card">
						<!-- Card Icon -->
						<div class="card-icon">
							<i class="fa-solid fa-utensils"></i>
						</div>
						<div class="card-body">
							<h5 class="card-title primary">Iftar & Taraweeh</h5>
							<p class="card-text">
								Iftar is provided daily during Ramadan after
								maghrib, and taraweeh is after Isha.
							</p>
							<button class="btn btn-primary">
								Read More
								<i class="fa-solid fa-arrow-right"></i>
							</button>
						</div>
					</div>
				</div>
				<br />
				<div class="col-md-4">
					<div class="card">
						<div class="card-icon">
							<i class="fa-solid fa-calendar-days"></i>
						</div>
						<div class="card-body">
							<h5 class="card-title primary">Community Center</h5>
							<p class="card-text">
								Rent our community center for your next event!
								We provide tables and chairs upon request.
							</p>
							<a href="#" class="btn btn-primary"
								>Read More
								<i class="fa-solid fa-arrow-right"></i
							></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Parallax>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import Parallax from "../components/Parallax.vue";
import SkewBox from "../components/SkewBox.vue";
import PrayerTimes from "@/components/PrayerTimes.vue";
export default {
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
		Parallax,
		SkewBox,
        PrayerTimes,
	},
	data() {
		return {
			windowHeight: 0,
			windowWidth: 0,
		};
	},
	methods: {
		getWindowSize() {
			this.windowHeight = window.innerHeight;
			this.windowWidth = window.innerWidth;
		},
	},
	beforeMount() {
		this.getWindowSize();
	},
	created() {
		window.addEventListener("resize", this.getWindowSize);
	},
	destroyed() {
		window.removeEventListener("resize", this.getWindowSize);
	},
};
</script>

<style>
/* #region Global Styles */
.btn-primary,
.btn-primary:visited,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:active:focus {
	background-color: var(--primary);
	color: rgb(255, 255, 255);
	border: none;
	outline: none;
	box-shadow: none;
	transition: all 0.25s ease-in-out;
}










table {
  line-height: 1;
  width: 100%;
  max-width: 80rem;
  margin: 0rem auto;
  }
  td {
    width: 20%;
    text-align: center;
    padding: 0rem;
  }
  
  tr:first-child {
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
  }
  
  tr:last-child {
    font-size: 1.5rem;
  }
  
  span {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0;
    vertical-align: top;
  }







.btn-primary:hover {
	background-color: var(--secondary);
}

.section {
	border-bottom: 5px solid var(--primary);
}

.large {
	font-size: 3.5rem;
}

p.large {
	font-size: 1.3rem;
}

.normal {
	font-weight: normal;
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}

.left {
	text-align: left;
}

.italicized {
	font-style: italic;
}

.primary {
	color: var(--primary);
}

.secondary {
	color: var(--secondary);
}

.accent {
	color: var(--accent);
}

.white {
	color: white;
}

.fb-image {
	margin: auto;
	display: block;
	width: 100%;
	border-radius: 20px;
}

.page-text {
	background-color: rgba(255, 255, 255, 0.5);
}

.no-decor,
.no-decor:hover,
.no-decor:focus,
.no-decor:active,
.no-decor:visited {
	text-decoration: none;
	color: var(--primary);
}
/* ====== CARDS ====== */
.card-title {
	text-align: center;
	color: var(--primary);
	transition: all 0.4s ease-in-out;
}

.card {
	padding: 1rem;
	transition: all 0.2s ease-in-out;
}

.card:hover {
	background-color: var(--primary);
	color: white !important;
}

.card .btn-primary,
.card .btn-primary:visited,
.card .btn-primary:active,
.card .btn-primary:focus,
.card .btn-primary:active:focus,
.card .btn-primary:hover {
	background-color: var(--primary);
	color: rgb(255, 255, 255);
	border: none;
	outline: none;
	box-shadow: none;
	margin: auto;
	display: block;
	width: fit-content;
	transition: all 0.25s ease-in-out;
	font-weight: 500;
}

.card:hover .btn-primary,
.card:hover .btn-primary:visited,
.card:hover .btn-primary:active,
.card:hover .btn-primary:focus,
.card:hover .btn-primary:active:focus,
.card:hover .btn-primary:hover {
	background-color: white;
	color: var(--primary);
	border: none;
	outline: none;
	box-shadow: none;
}

.card:hover .card-title {
	color: white;
}

.card-body {
	width: 100%;
}

.card-icon {
	font-size: 5rem;
	color: var(--primary);
	text-align: center;
	margin: auto;
	display: block;
	width: fit-content;
	transition: all 0.4s ease-in-out;
}

.card:hover .card-icon {
	color: white;
}

.card-text {
	text-align: center;
}

/* ====== Pillars ====== */
.pillar {
	width: 100% !important;
	height: 0;
	border-radius: 50%;
	border: 2px dotted white;
	background-color: rgba(var(--primaryRGB), 0.5);
	color: white;
	font-size: 5rem;
	text-align: center;
	margin: auto;
	display: flex;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	transition: all 0.4s ease-in-out;
	align-content: center;
	align-items: center;
	height: 0;
	padding-bottom: 100%;
}

.pillar i {
	margin: auto;
}

.col {
	flex: 1 0 0% !important;
	width: 18% !important;
	padding: 0 !important;
	margin: auto 20px auto !important;
}

.donate-btn {
	margin: auto;
	border-radius: 10rem;
}

.white-shadow {
	text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #fff, 0 0 4px #fff,
		0 0 5px #fff, 0 0 6px #fff, 0 0 7px #fff;
}

.black-shadow {
	text-shadow: 0 0 5px #000;
}

.link,
.link:focus,
.link:active,
.link:visited {
	color: var(--primary);
	font-weight: 500;
}

.link:hover {
	color: var(--secondary);
}

.primary-hr {
	margin: 0 0 15px 0;
	height: 2px !important;
	background: var(--primary);
	opacity: 1;
}

.row.block-center {
	width: unset !important;
}

.nav-pad {
	padding-top: 60px;
}

.vertical-center {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	justify-content: center;
	flex-direction: column;
}

.gold-border {
	border: 5px solid var(--primary);
}

.quran {
	background-color: rgba(146, 146, 146, 0.1);
	border-left: 10px solid var(--primary);
	margin: 1.5em 10px;
	padding: 0.5em 10px;
	quotes: "\201C""\201D";
	font-style: italic;
}
.quran:before {
	color: var(--primary);
	content: open-quote;
	font-size: 4em;
	line-height: 0.1em;
	margin-right: 0.25em;
	vertical-align: -0.4em;
}

.quran p {
	display: inline;
}

.mb-sm-4 {
	margin-bottom: 0 !important;
}
/* #endregion */

/* #region Spinner */
.lds-ring {
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 40px;
	height: 40px;
	margin: 8px 0;
	border: 5px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/* #endregion */

.col-md-4 {
	padding: 0 1rem !important;
}

.mycol-7 {
	flex: 0 0 auto;
	width: 14.2857142%;
}

.mycol-8 {
	flex: 0 0 auto;
	width: 12.5%;
}

.mycol-9 {
	flex: 0 0 auto;
	width: 11.1111111%;
}

.prayerSlide {
	margin: 0 15rem;
}

.carousel__pagination {
	padding: 0 !important;
}

.carousel__icon {
	color: white;
}

.carousel__pagination-button::after {
	background-color: white;
}

.carousel__pagination-button:hover::after,
.carousel__pagination-button--active::after {
	background-color: var(--secondary) !important;
}

/*
███    ███ ███████ ██████  ██  █████       ██████  ██    ██ ███████ ██████  ██ ███████ ███████ 
████  ████ ██      ██   ██ ██ ██   ██     ██    ██ ██    ██ ██      ██   ██ ██ ██      ██      
██ ████ ██ █████   ██   ██ ██ ███████     ██    ██ ██    ██ █████   ██████  ██ █████   ███████ 
██  ██  ██ ██      ██   ██ ██ ██   ██     ██ ▄▄ ██ ██    ██ ██      ██   ██ ██ ██           ██ 
██      ██ ███████ ██████  ██ ██   ██      ██████   ██████  ███████ ██   ██ ██ ███████ ███████ 
*/
/* 
BOOTSTRAP BREAKPOINTS:
  xs: 475px
  sm: 576px
  md: 768px
  lg: 992px
  xl: 1200px
  xxl: 1400px
*/

/* #region MEDIA QUERIES */
@media (max-width: 1399.98px) {
	.prayerSlide {
		margin: 0 5rem;
	}
}

@media (max-width: 1199.98px) {
	.large {
		font-size: 2.5rem;
	}

	p.large {
		font-size: 2.5vh !important;
	}

	.row {
		flex-direction: column !important;
		align-content: center !important;
		justify-content: center !important;
		align-items: center !important;
		display: flex !important;
		flex-wrap: nowrap !important;
	}

	.force-row.row {
		display: flex !important;
		flex-wrap: wrap !important;
		flex-direction: row !important;
	}

	.col {
		width: 200px !important;
	}

	.col .secondary.center {
		width: 100% !important;
		position: absolute !important;
		left: 50%;
		transform: translateX(-50%) !important;
	}

	.col-md-4 {
		width: 100% !important;
		padding: 1rem !important;
	}

	.gold-border {
		border: 3px solid var(--primary);
	}

	.col-8 {
		width: 100% !important;
	}

	.col-6 {
		margin-bottom: 3rem;
	}

	.mb-sm-4 {
		margin-bottom: 2rem !important;
	}

	.prayerSlide {
		margin: 0;
	}

	.col-4 {
	}

	.prayerSlide .col-4 {
		margin-bottom: 0rem !important;
		padding: 0.5rem 1rem;
		flex: 0 0 auto !important;
		width: 33.33333333% !important;
		height: 40px !important;
	}

	.prayerSlide .col-4:nth-child(2n) {
		background-color: hsl(147, 100%, 27%);
	}
	/* #endregion */
	
}
</style>

<style scoped>
#services .row > * {
	padding: 0;
}
</style>
