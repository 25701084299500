<template>
	<div><h1 class="white">Redirecting...</h1></div>
</template>

<script>
export default {
	beforeMount() {
		// Redirect to https://giv.li/zrxphv
		window.location.href = 'https://giv.li/zrxphv';
	},
};
</script>

<style scoped>
div {
	background-color: var(--primary);
	height: 100vh;
	width: 100vw;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
}
</style>