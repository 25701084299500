import { createStore } from "vuex";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    getFirestore,
    doc,
    setDoc,
    getDoc,
    updateDoc,
    arrayUnion,
} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyCtSoiJwRvsxN00HBGwzzGIPrJCPPxc9U0',
    authDomain: 'kcislamiccenter.firebaseapp.com',
    projectId: 'kcislamiccenter',
    storageBucket: 'kcislamiccenter.appspot.com',
    messagingSenderId: '892954836674',
    appId: '1:892954836674:web:d7c4b5057d0d137af4455b'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Connect to firestore and get database
const db = getFirestore(app);

const store = createStore({
    state: {
        email: "",
        iqamahTimes: {},
        jumuahTimes: {},
    },
    mutations: {
        setEmail(state, payload) {
            state.email = payload;
        },
        setIqamahTimes(state, payload) {
            state.iqamahTimes = payload;
        },
        setJumuahTimes(state, payload) {
            state.jumuahTimes = payload;
        }
    },
    actions: {
        async addEmail({ state }) {
            await updateDoc(doc(db, "mailing-list", "emails"), {
                emails: arrayUnion(state.email),
            });
        },
        // Get iqamahTimes doc from prayerTimes collection
        async getIqamahTimes({ commit }) {
            const docSnap = await getDoc(doc(db, "prayerTimes", "iqamahTimes"));

            if (docSnap.exists()) {
                commit("setIqamahTimes", docSnap.data());
            } else {
                console.log("No such document!");
            }
        },
        // Get jumuahTimes doc from prayerTimes collection
        async getJumuahTimes({ commit }) {
            const docSnap = await getDoc(doc(db, "prayerTimes", "jumuah"));

            if (docSnap.exists()) {
                commit("setJumuahTimes", docSnap.data());
            } else {
                console.log("No such document!");
            }
        }
    },
    getters: {
        // Get iqamahTimes from state
        getIqamahTimes(state) {
            return state.iqamahTimes;
        },
        // Get jumuahTimes from state
        getJumuahTimes(state) {
            return state.jumuahTimes;
        }
    },
});

export default store;
