import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Support from "../views/Support.vue";
import TVPrayerTimes from "../views/TVPrayerTimes.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
    {
        path: "/support",
        name: "Support",
        component: Support,
    },
	{
        path: "/tvprayertimes",
        name: "TVPrayerTimes",
        component: TVPrayerTimes,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
  });

export default router;
