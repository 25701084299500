<template>
	<footer>
		<div class="top-bg">
			<div class="container">
				<div class="footer-content">
					<div class="row block-center">
						<div class="col-4">
							<h2 class="primary">About Us</h2>
							<hr class="primary-hr" />
							<p style="margin-bottom: 0 !important">
								The Kansas City Islamic Center (KCIC)
								established in March of 2023 is aimed at
								serving the Muslim community of Downtown
								Kansas City Missouri and Kansas City Kansas.
							</p>
						</div>
						<div class="col-4">
							<h2 class="primary">Contact Us</h2>
							<hr class="primary-hr" />
							<div class="row force-row footer-spacer mb-xl-4">
								<div class="col-1">
									<i
										class="fa-solid fa-envelope primary icon"
									></i>
								</div>
								<div class="col-11">
									<div
										class="email"
										v-html="showEM('dijsamcick', 'moc.liamg')"
									></div>
								</div>
							</div>

							<h2 class="primary">Follow Us</h2>
							<hr class="primary-hr" />
							<div class="row force-row">
								<div class="col-1">
									<i
										class="fa-brands fa-facebook-square primary icon"
									></i>
								</div>
								<div class="col-11">
									<a
										href="https://www.facebook.com/profile.php?id=100093084463868&mibextid=ZbWKwL"
										class="link"
										target="_blank"
										>facebook</a
									>
								</div>
							</div>
						</div>
						<div class="col-4">
							<h2 class="primary">Join the Mailing List</h2>
							<hr class="primary-hr" />
							<p>
								Enter your email address to get notified
								whenever we have events.
							</p>
							<form @submit.prevent="addEmail">
								<div class="row block-center force-row">
									<div class="col-10 mailing-list">
										<input
											type="email"
											name=""
											id=""
											class="w-100"
											placeholder="Enter your email"
											v-model="email"
										/>
									</div>
									<div class="col-2 mailing-list">
										<button
											type="submit"
											class="btn btn-primary"
										>
											<i
												class="fa-solid fa-arrow-right"
											></i>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-bg">
			<div class="container">
				<hr style="border-bottom: 3px solid white; opacity: 1"/>
				<p class="white">
					Kansas City Islamic Center. &copy;
					{{ new Date().getFullYear() }}
				</p>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	data() {
		return {
			email: "",
		};
	},
	methods: {
		showEM(userName, emServer) {
			userName = this.stringReverse(userName);
			emServer = this.stringReverse(emServer);

			var emLink = userName + "@" + emServer;
			return (
				"<a class='link' href='mailto:" +
				emLink +
				"'>" +
				emLink +
				"</a>"
			);
		},
		stringReverse(textString) {
			if (!textString) return "";
			var revString = "";
			for (let i = textString.length - 1; i >= 0; i--)
				revString += textString.charAt(i);
			return revString;
		},
		addEmail() {
			this.$store.commit("setEmail", this.email);
			this.$store.dispatch("addEmail");
			this.email = "";
			this.$notify({
				title: "Success!",
				text: "Your email has been added to the mailing list.",
				type: "success",
			});
		},
	},
};
</script>

<style>
.top-bg {
	background-image: url("../assets/images/Footer.jpg"),
		linear-gradient(rgba(25, 25, 25, 0.05), rgba(25, 25, 25, 0.05));
	background-blend-mode: overlay;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
	overflow: visible;
}

.bottom-bg {
	background-color: var(--primary);
	position: relative;
}

.bottom-bg .container {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
}

.footer-content {
	background-color: #fafafa;
	/* height: 250px; */
	z-index: 10;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 30px;
	border-radius: 30px;
	padding: 2rem;
}

.footer-content h2 {
	margin-bottom: 2px;
}

.icon {
	font-size: 1.2rem;
	text-align: center;
	margin: auto;
}

.col-10.mailing-list {
	padding-right: 0 !important;
}

.col-2.mailing-list {
	padding-left: 0 !important;
	height: 30px !important;
}

.mailing-list .btn,
.mailing-list .btn:visited,
.mailing-list .btn:active,
.mailing-list .btn:focus,
.mailing-list .btn:active:focus {
	background-color: var(--primary);
	color: rgb(255, 255, 255);
	border: none;
	outline: none;
	box-shadow: none;
	transition: all 0.25s ease-in-out;
	font-weight: 500;
	height: 30px !important;
	padding: 0;
	width: 100%;
	border-bottom-left-radius: 0 !important;
}

.mailing-list .btn:hover {
	background-color: var(--secondary);
}

.mailing-list input {
	border: none;
	border-bottom: 2px solid var(--primary);
	background-color: transparent;
	color: var(--primary);
	font-size: 1rem;
	font-weight: 500;
	padding: 0;
	width: 100%;
	outline: none;
	height: 30px !important;
}

.success {
	background-color: var(--secondary) !important;
	border-left-color: var(--secondary) !important;
	color: black !important;
	padding: 0.5rem;
	border-radius: 5px;
	margin-bottom: 1rem;
}

@media (min-width: 280px) {
	.top-bg {
		height: 50px;
	}

	.bottom-bg {
		height: 900px;
	}
}

@media (min-width: 307px) {
	.top-bg {
		height: 50px;
	}

	.bottom-bg {
		height: 870px;
	}
}

@media (min-width: 311px) {
	.top-bg {
		height: 50px;
	}

	.bottom-bg {
		height: 850px;
	}
}

@media (min-width: 342px) {
	.top-bg {
		height: 50px;
	}

	.bottom-bg {
		height: 830px;
	}
}

@media (min-width: 356px) {
	.top-bg {
		height: 50px;
	}

	.bottom-bg {
		height: 800px;
	}
}

@media (min-width: 381px) {
	.top-bg {
		height: 50px;
	}

	.bottom-bg {
		height: 780px;
	}
}

@media (min-width: 473px) {
	.top-bg {
		height: 50px;
	}

	.bottom-bg {
		height: 760px;
	}
}

@media (min-width: 532px) {
	.top-bg {
		height: 50px;
	}

	.bottom-bg {
		height: 730px;
	}
}

@media (min-width: 576px) {
	.top-bg {
		height: 100px;
	}

	.bottom-bg {
		height: 680px;
	}
}

@media (max-width: 767.9px) {
	.footer-content {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.footer-content {
		width: 696px;
		height: 666px;
	}

	.top-bg {
		height: 300px;
	}

	.bottom-bg {
		height: 485px;
	}
}
@media (min-width: 992px) {
	.footer-content {
		width: 936px;
		height: 650px !important;
	}

	.top-bg {
		height: 300px;
	}

	.bottom-bg {
		height: 475px;
	}
}
@media (min-width: 1200px) {
	.footer-content {
		width: 1116px;
		height: unset !important;
	}

	.top-bg {
		height: 150px;
	}

	.bottom-bg {
		height: 220px;
	}
}
@media (min-width: 1400px) {
	.footer-content {
		width: 1296px;
	}
}

@media (max-width: 1199.98px) {
	.col-4 {
		width: 100% !important;
	}

	footer .col-4 {
		margin-bottom: 3rem;
	}

	.footer-spacer {
		margin-bottom: 3rem;
	}
}
</style>
