<template>
	<div class="container-xl py-5">
		<carousel
			:items-to-show="1"
			:modelValue="new Date().getDay()"
			v-if="prayerTimes && jumuahTimes && jumuahTimes.athaan"
		>
			<slide
				v-for="slide in prayerTimes"
				:key="slide"
				style="backgrund-color: red"
			>
				<div
					class="w-100 white prayerSlide px-xl-4 px-md-5 px-0 mx-lg-0 mx-md-5 mx-0"
				>
					<!-- DAY & DATE -->
					<div>
						<div>
							<h2 v-if="windowWidth >= 576">{{ currentTime }}</h2>
							<h2 class="center" v-if="windowWidth >= 576">
								{{ slide.date.gregorian.weekday.en }}
							</h2>
							<h2 class="center" v-if="windowWidth >= 576">
								{{ slide.date.readable }}
							</h2>
							<h2 class="center mb-4" v-if="windowWidth >= 576">
								{{ slide.date.hijri.day }}
								{{ slide.date.hijri.month.ar }}
								({{ slide.date.hijri.month.en }})
								{{ slide.date.hijri.year }}
							</h2>
						</div>

						<div>
							<h5 v-if="windowWidth < 576">{{ currentTime }}</h5>
							<h5 class="center" v-if="windowWidth < 576">
								{{ slide.date.gregorian.weekday.en }}
							</h5>
							<h5 class="center" v-if="windowWidth < 576">
								{{ slide.date.readable }}
							</h5>
							<h5 class="center mb-4" v-if="windowWidth < 576">
								{{ slide.date.hijri.day }}
								{{ slide.date.hijri.month.ar }}
								({{ slide.date.hijri.month.en }})
								{{ slide.date.hijri.year }}
							</h5>
						</div>
					</div>

					<!-- PRAYER TIMES -->
					<div>
						<div
							class="mx-0 force-row row"
							v-if="windowWidth >= 992"
						>
							<div class="mycol-9 center"></div>
							<div class="mycol-9 center">
								<strong>Fajr</strong>
							</div>
							<div class="mycol-9 center">
								<strong>Sunrise</strong>
							</div>
							<div class="mycol-9 center">
								<strong>Dhuhr</strong>
							</div>
							<div class="mycol-9 center">
								<strong>Asr</strong>
							</div>
							<div class="mycol-9 center">
								<strong>Maghrib</strong>
							</div>
							<div class="mycol-9 center">
								<strong>Isha</strong>
							</div>
							<div class="mycol-9 center">
								<strong>Midnight</strong>
							</div>
							<div class="mycol-9 center">
								<strong>Jumu'ah</strong>
							</div>

							<div class="mycol-9 center">
								<strong>Athaan</strong>
							</div>
							<div class="mycol-9 center">
								{{ slide.timings.Fajr }}
							</div>
							<div class="mycol-9 center">
								{{ slide.timings.Sunrise }}
							</div>
							<div class="mycol-9 center">
								{{ slide.timings.Dhuhr }}
							</div>
							<div class="mycol-9 center">
								{{ slide.timings.Asr }}
							</div>
							<div class="mycol-9 center">
								{{ slide.timings.Maghrib }}
							</div>
							<div class="mycol-9 center">
								{{ slide.timings.Isha }}
							</div>
							<div class="mycol-9 center">
								{{ slide.timings.Midnight }}
							</div>
							<div class="mycol-9 center">
								{{ jumuahTimes.athaan }}
							</div>

							<div class="mycol-9 center">
								<strong>Iqaamah</strong>
							</div>

							<div class="mycol-9 center">
								{{ slide.iqamahTimes.Fajr }}
							</div>
							<div class="mycol-9 center"></div>
							<div class="mycol-9 center">
								{{ slide.iqamahTimes.Dhuhr }}
							</div>
							<div class="mycol-9 center">
								{{ slide.iqamahTimes.Asr }}
							</div>
							<div class="mycol-9 center">
								{{ slide.iqamahTimes.Maghrib }}
							</div>
							<div class="mycol-9 center">
								{{ slide.iqamahTimes.Isha }}
							</div>
							<div class="mycol-9 center"></div>
							<div class="mycol-9 center">
								{{ jumuahTimes.iqamah }}
							</div>
						</div>

						<div class="mx-0 force-row row" v-else>
							<div class="col-4">‎</div>
							<div class="col-4 center">
								<strong>Athaan</strong>
							</div>
							<div class="col-4 left">
								<strong>Iqaamah</strong>
							</div>
							<div class="col-4 right">
								<strong>Fajr</strong>
							</div>
							<div class="col-4 center">
								{{ slide.timings.Fajr }}
							</div>
							<div class="col-4 left">
								{{ slide.iqamahTimes.Fajr }}
							</div>
							<div class="col-4 right">
								<strong>Sunrise</strong>
							</div>
							<div class="col-4 center">
								{{ slide.timings.Sunrise }}
							</div>
							<div class="col-4">‎</div>
							<div class="col-4 right">
								<strong>Dhuhr</strong>
							</div>
							<div class="col-4 center">
								{{ slide.timings.Dhuhr }}
							</div>
							<div class="col-4 left">
								{{ slide.iqamahTimes.Dhuhr }}
							</div>
							<div class="col-4 right">
								<strong>Asr</strong>
							</div>
							<div class="col-4 center">
								{{ slide.timings.Asr }}
							</div>
							<div class="col-4 left">
								{{ slide.iqamahTimes.Asr }}
							</div>
							<div class="col-4 right">
								<strong>Maghrib</strong>
							</div>
							<div class="col-4 center">
								{{ slide.timings.Maghrib }}
							</div>
							<div class="col-4 left">
								{{ slide.iqamahTimes.Maghrib }}
							</div>
							<div class="col-4 right">
								<strong>Isha</strong>
							</div>
							<div class="col-4 center">
								{{ slide.timings.Isha }}
							</div>
							<div class="col-4 left">
								{{ slide.iqamahTimes.Isha }}
							</div>
							<div class="col-4 right">
								<strong>Midnight</strong>
							</div>
							<div class="col-4 center">
								{{ slide.timings.Midnight }}
							</div>
							<div class="col-4">‎</div>
							<div class="col-4 right">
								<strong>Jumu'ah</strong>
							</div>
							<div class="col-4 center">
								{{ jumuahTimes.athaan }}
							</div>
							<div class="col-4 left">
								{{ jumuahTimes.iqamah }}
							</div>
						</div>
					</div>
				</div>
			</slide>

			<template #addons>
				<navigation v-if="windowWidth >= 992" />
				<pagination />
			</template>
		</carousel>

		<h1 class="center white" v-else>
			<div class="lds-ring">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
			Loading prayer times...
		</h1>
	</div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import PrayerTimes from "@/components/PrayerTimes.vue";
export default {
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
		PrayerTimes,
	},
	data() {
		return {
			prayerTimes: null,
			jumuahTimes: null,
			windowHeight: 0,
			windowWidth: 0,
			times: {},
			currentTime: "",
			interval: {},
		};
	},
	methods: {
		getWindowSize() {
			this.windowHeight = window.innerHeight;
			this.windowWidth = window.innerWidth;
		},
		async getPrayerTimes() {
            var debug = false;

			// Fetch data from Al-Adhahan API
			var currentData = await fetch(
				`https://api.aladhan.com/v1/calendarByAddress/${this.times.currentYear}/${this.times.currentMonth}?address=923 S Bethany St, Kansas City, KS&method=2&adjustment=2`,
				{
					method: "GET",
					redirect: "follow",
				}
			).then(async (response) => {
				return await response.json().then((data) => {
					return data.data;
				});
			});

			var nextData = await fetch(
				`https://api.aladhan.com/v1/calendarByAddress/${this.times.nextYear}/${this.times.nextMonth}?address=923 S Bethany St, Kansas City, KS&method=2&adjustment=2`,
				{
					method: "GET",
					redirect: "follow",
				}
			).then(async (response) => {
				return await response.json().then((data) => {
					return data.data;
				});
			});

			var previousData = await fetch(
				`https://api.aladhan.com/v1/calendarByAddress/${this.times.previousYear}/${this.times.previousMonth}?address=923 S Bethany St, Kansas City, KS&method=2&adjustment=2`,
				{
					method: "GET",
					redirect: "follow",
				}
			).then(async (response) => {
				return await response.json().then((data) => {
					return data.data;
				});
			});

			// Debug print "currentData", currentData
            if (debug) console.log("currentData", currentData);

			this.prayerTimes = await this.formatPrayers(
				await this.getCurrentWeek(currentData, nextData, previousData)
			);
		},
		convertTo12Hr(time) {
			return (
				((Number(time.substring(0, 2)) + 11) % 12) +
				1 +
				time.substring(2, 5) +
				" " +
				(Number(time.substring(0, 2)) > 12 ? "PM" : "AM")
			);
		},
		convertDayTo12Hr(timings) {
			timings.Fajr = this.convertTo12Hr(timings.Fajr);
			timings.Sunrise = this.convertTo12Hr(timings.Sunrise);
			timings.Dhuhr = this.convertTo12Hr(timings.Dhuhr);
			timings.Asr = this.convertTo12Hr(timings.Asr);
			timings.Maghrib = this.convertTo12Hr(timings.Maghrib);
			timings.Isha = this.convertTo12Hr(timings.Isha);
			timings.Midnight = this.convertTo12Hr(timings.Midnight);

			return timings;
		},
		getIqamahTime(time, iqamahDelay) {
			var hour = Number(time.split(":")[0]);
			var minute = Number(time.split(":")[1].substring(0, 2));
			var ampm = time.split(":")[1].substring(3, 5);

			// Add the iqamah delay to the prayer time
			minute += iqamahDelay;

			// If the minute is greater than 60, add 1 to the hour and subtract 60 from the minute
			if (minute >= 60) {
				hour += 1;
				minute -= 60;
			}

			// If the hour is greater than 12, subtract 12 from the hour, and change the ampm
			if (hour > 12) {
				hour -= 12;
				ampm = ampm == "AM" ? "PM" : "AM";
			}

			// Set the iqamahTime
			return (
				hour + ":" + (minute < 10 ? "0" + minute : minute) + " " + ampm
			);
		},
		async getCurrentWeek(currentData, nextData, previousData) {
            var debug = false;

			var res = [];
            // Debug print "GET CURRENT WEEK START"
            if (debug) console.log("GET CURRENT WEEK START", res);

			var currentSunday =
				this.times.currentDay - this.times.date.getDay();

			// Check if the most recent Sunday is in the previous month
			if (currentSunday < 1) {
                // Debug print "Sunday in previous month"
                if (debug) console.log("Sunday in previous month");

				// If it is, get the number of days in the previous month
				var daysInPreviousMonth = new Date(
					this.times.previousYear,
					this.times.previousMonth,
					0
				).getDate();

				// Get the this.times.date of the most recent Sunday in the previous month
				currentSunday = daysInPreviousMonth + currentSunday;

				// Loop through the previous month's data starting from the most recent Sunday, up until the end of the month
				for (var i = currentSunday; i <= daysInPreviousMonth; i++) {
					// Get the prayer times for the current day
					var prayerTimesForDay = previousData[i - 1];

					// Add the prayer times for the current day to the prayerTimes array
					res.push(prayerTimesForDay);
				}

				// Loop through the current month's data starting from the first day, up until the current day
				for (var i = 1; i <= this.times.currentDay; i++) {
					// Get the prayer times for the current day
					var prayerTimesForDay = currentData[i - 1];

					// Add the prayer times for the current day to the prayerTimes array
					res.push(prayerTimesForDay);
				}
			}
			// Else, if the most recent Sunday is in the current month
			else {
                // Debug print "Sunday in current month"
                if (debug) console.log("Sunday in current month");
                
				// Get the this.times.date of the next Saturday
				var nextSaturday =
					this.times.currentDay + (6 - this.times.date.getDay());

				// Check if the next Saturday is in the next month
				if (
					nextSaturday >
					new Date(
						this.times.currentYear,
						this.times.currentMonth,
						0
					).getDate()
				) {
                    // Debug print "Saturday in next month"
                    if (debug) console.log("Saturday in next month", res);

                    nextSaturday = 6 - this.times.date.getDay();

					// If it is, loop through the current month's data starting from the current sunday, up until the end of the month
					for (
						var i = currentSunday;
						i <=
						new Date(
							this.times.currentYear,
							this.times.currentMonth,
							0
						).getDate();
						i++
					) {
						// Get the prayer times for the current day
						var prayerTimesForDay = currentData[i - 1];

						// Add the prayer times for the current day to the prayerTimes array
						res.push(prayerTimesForDay);
					}

                    // Debug print "res after first loop", res
                    if (debug) console.log("res after first loop", res);

                    // Debug print "nextSaturday", nextSaturday
                    if (debug) console.log("nextSaturday", nextSaturday);

					// Loop through the next month's data starting from the first day, up until the next Saturday
					for (var i = 1; i <= nextSaturday; i++) {
						// Get the prayer times for the current day
						var prayerTimesForDay = nextData[i - 1];

						// Add the prayer times for the current day to the prayerTimes array
						res.push(prayerTimesForDay);
					}
				}
				// Else, if the next Saturday is in the current month
				else {
                    // Debug print "Saturday in current month"
                    if (debug) console.log("Saturday in current month");

					// Loop through the current month's data starting from the previous Sunday, up until the next Saturday
					for (var i = currentSunday; i <= nextSaturday; i++) {
						// Get the prayer times for the current day
						var prayerTimesForDay = currentData[i - 1];

						// Add the prayer times for the current day to the prayerTimes array
						res.push(prayerTimesForDay);
					}
				}
			}

			// Debug print res
			if (debug) console.log("GET CURRENT WEEK RES", res);

			return res;
		},
		async formatPrayers(prayerTimes) {
			var iqamahTimes = await this.$store
				.dispatch("getIqamahTimes")
				.then(() => {
					return this.$store.getters.getIqamahTimes;
				});

			for (let i = 0; i < prayerTimes.length; i++) {
				// Convert all timings in the current day to standard 12hr format
				prayerTimes[i].timings = this.convertDayTo12Hr(
					prayerTimes[i].timings
				);

				// Add iqamah times for each prayer
				prayerTimes[i].iqamahTimes = {
					Fajr: this.getIqamahTime(
						prayerTimes[i].timings.Fajr,
						Number(iqamahTimes.Fajr)
					),

					Dhuhr: this.getIqamahTime(
						prayerTimes[i].timings.Dhuhr,
						Number(iqamahTimes.Dhuhr)
					),

					Asr: this.getIqamahTime(
						prayerTimes[i].timings.Asr,
						Number(iqamahTimes.Asr)
					),

					Maghrib: this.getIqamahTime(
						prayerTimes[i].timings.Maghrib,
						Number(iqamahTimes.Maghrib)
					),

					Isha: this.getIqamahTime(
						prayerTimes[i].timings.Isha,
						Number(iqamahTimes.Isha)
					),
				};
			}

			return prayerTimes;
		},
	},
	async mounted() {
		// Initialize date values
		this.times = {
			date: new Date(),
			currentDay: new Date().getDate(),
			currentMonth: new Date().getMonth() + 1,
			currentYear: new Date().getFullYear(),
			nextMonth: new Date().getMonth() + 2,
			nextYear: new Date().getFullYear(),
			previousMonth: new Date().getMonth(),
			previousYear: new Date().getFullYear(),
		};

		// Check if the next month is in the next year
		if (this.times.nextMonth > 12) {
			this.times.nextMonth = 1;
			this.times.nextYear++;
		}

		// Check if the previous month is in the previous year
		if (this.times.previousMonth < 1) {
			this.times.previousMonth = 12;
			this.times.previousYear--;
		}

		await this.getPrayerTimes();

		this.jumuahTimes = await this.$store
			.dispatch("getJumuahTimes")
			.then(() => {
				return this.$store.getters.getJumuahTimes;
			});
	},
	beforeMount() {
		this.getWindowSize();
	},
	created() {
		window.addEventListener("resize", this.getWindowSize);

		this.interval = setInterval(() => {
			this.currentTime = new Date().toLocaleTimeString();
		}, 1000);
	},
	destroyed() {
		window.removeEventListener("resize", this.getWindowSize);
	},
};
</script>


<style>
</style>
