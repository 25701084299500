<template>
	<!-- HERO -->
	<Parallax
		:image="{
			backgroundImage: `url(${require('@/assets/images/misc/KansasCity.jpg')})`,
			'background-position': 'top',
		}"
		:height="'50vh'"
		:opacity="0.3"
		:overlayColor="'0, 0, 0'"
		class="section"
	>
		<div class="container nav-pad" style="height: 50vh">
			<div style="display: flex; align-items: center; justify-content: center; gap: 3rem">
				<img
					src="@/assets/images/logo/KCIC_Decorative_Logo.png"
					style="max-height: 35vh; max-width: 100%"
				/>
			</div>
		</div>
	</Parallax>

	<!-- PRAYER TIMES -->
	<div
		class="section"
		style="
			background-color: var(--primary);
			height: calc(50vh - 5px);
			display: flex;
			align-items: center;
		"
	>
		<PrayerTimes />
	</div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import Parallax from "../components/Parallax.vue";
import PrayerTimes from "@/components/PrayerTimes.vue";
export default {
	components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Parallax,
    PrayerTimes
},
	data() {
		return {
			windowHeight: 0,
			windowWidth: 0,
		};
	},
	methods: {
		getWindowSize() {
			this.windowHeight = window.innerHeight;
			this.windowWidth = window.innerWidth;
		},
	},
	beforeMount() {
		this.getWindowSize();
	},
	created() {
		window.addEventListener("resize", this.getWindowSize);
        
		this.interval = setInterval(() => {
			this.currentTime = new Date().toLocaleTimeString();
		}, 1000);
	},
	destroyed() {
		window.removeEventListener("resize", this.getWindowSize);
	},
};
</script>


<style scoped>
#services .row > * {
	padding: 0;
}
</style>
